/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import ym from 'react-yandex-metrika';
import styled from 'styled-components';
import loadable from '@loadable/component';

import { GQL_GET_PAGE } from '../graphql/queries';
import { ContentContainer } from '../helpers/mainStyles';
import { RU_DOMAIN } from '../locales';

const MetaTags = loadable(() => import('../components/MetaTags'));
const Error = loadable(() => import('./Error'));

const Page = ({ match }) => {
  const { error, loading, data } = useQuery(GQL_GET_PAGE, { variables: match.params });

  useEffect(() => {
    ym('hit', `/${_.get(match, 'params.url', 'some_page')}`);
  }, []);

  const item = _.get(data, 'page');
  const errors = _.get(error, 'graphQLErrors', []);

  if (!_.isEmpty(errors)) {
    return <Error errors={errors} />;
  }
  if (_.isEmpty(item) && !loading) {
    return <Error errors={[]} />;
  }

  const meta = {
    title: _.get(item, 'meta.title', ''),
    description: _.get(item, 'meta.description', ''),
    path: `${RU_DOMAIN}/${match.params.url}`,
    updated: _.get(item, 'updated_at', null),
    published: _.get(item, 'created_at', null),
  };

  return (
    <PageBox>
      {loading && (
        <div className="loader">
          <div className="bar" />
        </div>
      )}
      <MetaTags {...meta} />
      <ContentContainer>
        <H1>{_.get(item, 'name')}</H1>
        <Description dangerouslySetInnerHTML={{ __html: _.get(item, 'description') }} />
      </ContentContainer>
    </PageBox>
  );
};

const H1 = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 40px;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media screen and (max-width: 812px) {
    font-size: 24px;
  }
`;

const Description = styled.div`
  background-color: white;
  padding: 50px;
  @media screen and (max-width: 812px) {
    padding: 20px;
  }
`;

const PageBox = styled.section`
  margin-top: 3rem;
  padding-bottom: 5rem;
`;

Page.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Page;
